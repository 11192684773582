import {ApiLoadByHour} from "../../api/index";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Translation} from "react-i18next";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";
import MainLoader from "../../ui/loaders/MainLoader";
import {Card, Col, Row} from "react-bootstrap";
import DateTimeField from "../../crud/FormInputs/DateTimeField";

export const LoadByHour = () => {
    const [loadData, setLoadData] = useState<any>();
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());

    useEffect(() => {
        getData().then();
    }, [dateFrom, dateTo])

    useEffect(() => {
        checkoutStore.getData().then();
        getData().then();
        breadcrumbsStore.items = [{name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'}];
    }, [])

    const formatByRole = () => {
        let result = []
        for (let key in loadData.roles) {
            loadData.roles[key].date = Date.parse(key + "Z");
            result.push(loadData.roles[key])
        }
        console.log(result)
        return result;
    }

    const getData = () => {
        return ApiLoadByHour(dateFrom, dateTo)
            .then((resp: any) => {
                setLoadData(resp.data);
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title>Загрузка по часам</Card.Title>
                <Row>
                    <Col>
                        <DateTimeField name={'dateFrom'}
                                       dateFormat={"dd.MM.yyyy"}
                                       model="dateFrom"
                                       label="От"
                                       size={"sm"}
                                       showTimeSelect={false}
                                       onChange={(val: any) => setDateFrom(val)}
                                       value={dateFrom}/>
                    </Col>
                    <Col>
                        <DateTimeField name={'dateTo'}
                                       dateFormat={"dd.MM.yyyy"}
                                       model="dateTo"
                                       label="До"
                                       size={"sm"}
                                       showTimeSelect={false}
                                       onChange={(val: any) => setDateTo(val)}
                                       value={dateTo}/>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                {
                    (loadData) ?
                        <ResponsiveContainer width={'100%'} height={300}>
                            <BarChart data={formatByRole()} margin={{top: 5, right: 5, left: 5, bottom: 5}}>
                                <CartesianGrid strokeDasharray="3 3" horizontal={true}/>

                                <XAxis dataKey="date"
                                       tickFormatter={
                                           timeStr => moment(timeStr / 1000, 'X').format('HH:mm')
                                       }/>
                                <YAxis/>
                                <Tooltip
                                    // formatter={(value) => {return "dd" + value + "dd";}}
                                    labelFormatter={
                                        (value) => moment(value / 1000, 'X').format('DD.MM.yyyy HH:mm')
                                    }
                                />
                                <Legend/>
                                <Bar dataKey="role_group_3" name="Админы" stackId="a" fill="#23272b"/>
                              {/*  <Bar dataKey="role_group_5" name="Игроведы" stackId="a" fill="#e0a800"/>*/}
                                <Bar dataKey="role_group_6" name="Фришники" stackId="a" fill="#4d0000"/>
                                <Bar dataKey="role_group_1" name="Гости" stackId="a" fill="#828a8f"/>
                                <Bar dataKey="role_group_2" name="Постоянники" stackId="a" fill="#218838"/>
                                <Bar dataKey="role_group_7" name="Знакомые" stackId="a" fill="#e0a800"/>
                            </BarChart>
                        </ResponsiveContainer>
                        : <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                }
            </Card.Body>
        </Card>
    )
}
